$include-fonts: false;

@import "../setup/settings";
@import "./loginRegisterStyle.scss";

.registration-phone-confirm-block{
    width: 100%;
    margin-top: rem-calc(34);
    @include breakpoint (medium down){
        margin-top: rem-calc(3);
    }
    .insert-phone-number.opt-in-flow{
        margin-top: 0;
        text-align: left;
    }
}
.registerPhoneButton{
        width: rem-calc(450) !important;
        position: unset;
    @include breakpoint (medium down){
        width: 100%!important;
        margin-top: rem-calc(4);
    }
}
.registration-page-form-block{
    .phone-inputs{
        justify-content: center;
        position: relative;
        @include breakpoint (medium up) {
            top: rem-calc(40);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .input-select-item-register{
        @include breakpoint (medium up) {
            width: rem-calc(103);
        }
        margin-right: rem-calc(16);
    }
    .number-select-item-register{
        @include breakpoint (medium up) {
            width: rem-calc(304);
        }
    }
    .button-register-phone{
        display: flex;
        justify-content: center;
        @include breakpoint (medium up) {
            width: rem-calc(423);
        }
    }
}
.registration-next-step-phone{
    min-width: rem-calc(240)!important;
    height: rem-calc(40);
    @include breakpoint (medium down){
        margin-top: rem-calc(4);
    }
}
.title-page-font-18{
    text-align: center;
    font-size: rem-calc(18);
}
.ico-back-registration{
    border: 0;
}
.fix-padding-large{
    @include breakpoint(large up){
        padding: 0;
    }
}
.login-banner{
    @include breakpoint(small down){
        padding: rem-calc(0 50);
    }
}
.change-password-form{
    margin-top: rem-calc(60);
    @include breakpoint(medium down){
        margin-top: rem-calc(50);
    }
    @include breakpoint(small down){
        input{
            margin-bottom: rem-calc(20);
        }
    }
    .btn-new-password-confirm button{
        min-width: rem-calc(240);
        margin-top: rem-calc(40);
        @include breakpoint(small down){
            width: 100%;
        }
    }
    .alert-password-changed{
        color: #FF6699;
        margin-bottom: rem-calc(15);
        img{
            margin-right: rem-calc(10);
        }
    }
    .btn-new-password-done{
        margin-top: rem-calc(40);
        @include breakpoint(small down){
            margin-top: rem-calc(50);
        }
        a{
            min-width: rem-calc(240);
            margin-right: rem-calc(20);
            @include breakpoint(small down){
                width: 100%;
                margin-bottom: rem-calc(20);
            }
        }
    }
}
.password-tip{
    color: $medium-gray;
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.2);
}
.request-password-title{
    font-size: rem-calc(18);
    margin-bottom: rem-calc(10);
}

.remember-me-container{
    span{
        .slider {
            height: rem-calc(16);
            width: rem-calc(32);
            @include breakpoint (medium down){
                height: rem-calc(17);
                width: rem-calc(32);
            }
            @include breakpoint (ipad down){
                height: rem-calc(25);
                width: rem-calc(50);
            }
            .round{
                height: rem-calc(17);
                width: rem-calc(32);
                &:before{
                    top: 0.10385rem !important;
                }
            }
        }

        .slider:before {
            @include breakpoint (medium down){
                height: rem-calc(20);
                width: rem-calc(20);
                top: rem-calc(2.5);
            }
        }
        input:checked + .slider {
            background-color: #82C1BD;
        }

        input:checked + .slider:before {
            background: #fff;
            @include breakpoint (medium down){
                left: rem-calc(10);
            }
        }
    }
}

.datepicker{
    th{
        position: unset;
    }
}

.policy-promotion-payment,
.policy-promotion-loyalty,
.loyalty-page {
    /* The switch - the box around the slider */
    .add-to-email-list-yes{
        padding-right: rem-calc(20);
    }
    .my-intimissimi-card-register{
        font-size: rem-calc(18);
        line-height: rem-calc(30);
        @include breakpoint(ipad down){
            font-size: rem-calc(22);
            line-height: rem-calc(32);
            margin: rem-calc(20 0);
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: rem-calc(40);
        height: rem-calc(15);
        margin-top: rem-calc(6);
        margin-right: rem-calc(20);
        @include breakpoint(small down){
            margin-top: rem-calc(5);
        }
        input:disabled + .slider {
            opacity: 0.5;
        }
        input{
            height: rem-calc(30) !important;
        }
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #646464;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        background-color:#F2F4ED;
        -webkit-transition: .4s;
        transition: .4s;
        top: rem-calc(2);
        @include breakpoint (ipad down){
            height: rem-calc(24);
            width: rem-calc(24);
            top: rem-calc(4);
        }
    }
    .slider.loyalty-highlight{
        z-index: 0;
        &::after{
            position: absolute;
            content: "";
            height: rem-calc(46);
            width: rem-calc(46);
            left: -18px;
            bottom: -15px;
            border-radius: 50%;
            background-color:rgba($color: #82C1BD, $alpha: 0.5);
            z-index: -1;
            @include breakpoint (small down){
                bottom: rem-calc(-10);
            }
        }
    }

    input:checked + .slider {
        background-color: #82C1BD;
    }

    input:checked + .slider:before {
        background: #fff;
        top: rem-calc(2);
        @include breakpoint (ipad down){
            top: rem-calc(4);
        }
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: rem-calc(34);
        height: rem-calc(16);
        width: rem-calc(32);
        @include breakpoint (ipad down){
            height: rem-calc(32);
            width: rem-calc(65.33);
        }
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .opposition-field-my-intimissimi{
        margin-top: rem-calc(10);
    }
    .info-icon{
        margin: rem-calc(2 0 0 10);
        top: rem-calc(-17) !important;
        .page[data-action="Account-RegistrationFormShow"] &{
            top: 0 !important;
        }
        @include breakpoint(small down){
            margin-left: rem-calc(30);
        }
        &:hover + .tooltip-arrow-down {
            opacity: 1;
            visibility: visible;
        }
        .icon-info-black {
            position: absolute;
            left: rem-calc(-13);
            top: rem-calc(-2);
            @include breakpoint (medium down){
                left: rem-calc(-5);
                top: rem-calc(-2);
            }
        }
        .tooltip{
            overflow-wrap: break-word;
            @include breakpoint (medium down){
                left: -240px;
            }
            @include breakpoint(small down){
                left: -100px;
                width: rem-calc(250);
            }
        }
    }
    .link-privacy-loyalty-1{
        font-size: rem-calc(13);
        font-weight: 400;
    }
    .link-privacy-loyalty-2{
        font-size: rem-calc(13);
    }
}

.popover-remember-me{
    overflow-wrap: break-word;
}

.remember-me-container{
    .info-icon{
        .tooltip {
            &::before{
                border: 0;
            }
            filter: none;
            left: 0;
            top: -15;
            width: rem-calc(250);
            @include breakpoint (ipad down){
                left: 0;
                top: 0;
                width: rem-calc(250);
            }
            @include breakpoint (small down){
                left: 50%;
                top: 50%;
                width: 90%;
           }
            .info-rememberme{
                background: white;
                border-radius: 5px;
                color: #666666;
                font-size: rem-calc(11);
                width: rem-calc(250);
                min-height: rem-calc(100);
                padding: rem-calc(20);
            }
        }
    }
}

.registration-social .privacy-cookie-policy{
    margin-bottom: rem-calc(40);
    &.privacy-cookie-policy-bottom{
        @include breakpoint(medium down){
            text-align: center;
        }
    }
}
.privacy-cookie-policy-bottom{
    margin-top: rem-calc(40);
    @include breakpoint(ipad down){
        margin-top: rem-calc(32);
    }
}
.fix-margin{
    @include breakpoint(medium up){
        margin-top: rem-calc(20);
    }
    &-social{
        margin-bottom: rem-calc(38) !important;
    }
}
a.btn-modal-link{
    text-decoration: underline;
}
.policy-promotion-payment p,
.registration-policy-prefilled{
    margin-bottom: rem-calc(20);
    @include breakpoint(large up){
        margin-bottom: rem-calc(12);
    }
    text-align: left;
}
.policy-promotion-payment,
.privacy-cookie-policy{
    span,
    a,
    p{
        @include breakpoint(large up){
            font-size: rem-calc(12);
            line-height: rem-calc(16);
        }
        @include breakpoint(ipad down){
            font-weight: 500;
        }
    }
}
button.social{
    @include breakpoint(medium down){
        width: 100%;
    }
    @include breakpoint(small down){
        margin-bottom: rem-calc(60);
    }
}
button.extended{
    width: 100%;
}

.subtitle-persistent-login{
    overflow-wrap: break-word;
}

.checkbox-label{
    &.remember-me-label{
        margin-left: rem-calc(40);
        margin-top: 0;
        @include breakpoint (small down){
            margin: rem-calc(4 0 0 58);
        }
    }
}

.tez-loyalty-img{
    @include breakpoint (medium down){
        display: block;
        margin-right: auto;
        width: 25% !important;
    }
}

.page--login,
.page[data-action="Login-Show"]{
    .fixed-image-login-no-scroll{
        position: fixed;
        width: 50%;
        height: 100%;
        object-fit: fill;
        .content-asset{
            img{
                width: 100%;
            }
        }
    }
    .tabs{
        border-bottom: 1px solid $light-gray;
        margin-bottom: rem-calc(48);
        @include breakpoint(ipad down){
            margin: rem-calc(33 0 24);
        }
        @include breakpoint (small down){
            margin: rem-calc(0 0 24 0);
        }
        .tab-link{
            width: 100%;
            font-weight: 600;
            text-align: center;
            font-size: rem-calc(18);
            line-height: rem-calc(27);
            padding-bottom: 0;
            &:not(.current){
                color: $medium-light-gray;
                margin-top: rem-calc(11);
                margin-bottom: rem-calc(16);
                border-bottom: 0;
            }
            &:nth-child(2){
                border-right: 0;
            }
            &.current{
                margin-top: rem-calc(9);
                margin-bottom: rem-calc(18);
                border-bottom: 0;
            }
        }
    }
    .tab-content{
        .registration-page-title-tab,
        .login-page-title-tab{
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            font-style: normal;
            font-weight: 500;
            letter-spacing: rem-calc(0.2);
            color: #1C1C1C;
            @include breakpoint(ipad down){
                line-height: rem-calc(24);
                padding-left: rem-calc(180);
            }
            @include breakpoint (small down){
                line-height: rem-calc(24);
                padding-left: 0;
            }
        }
        .registration-page-title-tab{
            margin-bottom: rem-calc(26);
            @include breakpoint(ipad down){
                margin-bottom: rem-calc(24);
            }
        }
        .login-page-title-tab{
            margin-bottom: rem-calc(26);
            @include breakpoint(ipad down){
                margin-bottom: rem-calc(24);
            }
        }
        .registration-page-subtitle-tab{
            margin-bottom: rem-calc(26);
            @include breakpoint(ipad down){
                margin-bottom: rem-calc(44);
                padding-left: rem-calc(180);
            }
            @include breakpoint (small down){
                margin-bottom: rem-calc(44);
                padding-left: 0;
            }
        }
        .registration-page-title-tab,
        .login-page-title-tab,
        .registration-page-subtitle-tab{
            @include breakpoint(medium down){
                text-align: left;
            }
        }
        .login-pwd{
            margin-bottom: rem-calc(26);
            font-size: rem-calc(12);
            margin-top: rem-calc(26);
            @include breakpoint(ipad down){
                margin: rem-calc(20 0 32);
            }
            a{
                border-width: rem-calc(2);
            }
        }
        #login-form-email{
            @include breakpoint(large up){
                margin-bottom: 0;
            }
        }

        .errors-list.filled{
            //margin-top: rem-calc(25);
            margin-bottom: 0;
        }

        .login-form-email-spacer{
            @include breakpoint(large up){
                margin-bottom: rem-calc(26);
            }
        }

        .info-icon-position-right{
            top: 0;
            margin-left: rem-calc(8);
            width: rem-calc(16);
            height: rem-calc(16);
            @include breakpoint (medium down){
              top: rem-calc(1);
              right: rem-calc(12);
              margin-top: rem-calc(3);
              width: rem-calc(16);
              height: rem-calc(16);
            }
        }
    }

    .d-flex{
        display: flex;
    }
    .full-width-page{
        margin-bottom: rem-calc(100);
        @include breakpoint(small down){
            margin-top: rem-calc(30);
        }
    }
    @include breakpoint(medium down){
        overflow-x: clip;
        position: relative;
    }
    select{
        //background-image: url('../images/arrow-down-fat.svg');
    }

    #tab-login-cell{
        .toggle-password{
            cursor: pointer;
            position: absolute;
            top: rem-calc(27);
            left: rem-calc(370);
            content: url('../../images/password-eye-closed.svg');
            width: rem-calc(20);
            height: rem-calc(20);
            z-index: 1;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px;
            touch-action: manipulation;
            &.pwd-icon-visible{
                content: url('../../images/password-eye-open.svg');
            }
            @include breakpoint (small down){
                left: rem-calc(320);
            }
        }
        .calendar-datepicker-img{
            position: absolute;
            top: rem-calc(20);
            right: rem-calc(10);
            width: rem-calc(20);
        }
        .email-input{
            margin-bottom: rem-calc(26);
        }
        .password-input{
            margin-bottom: rem-calc(26);
        }
    }
    #tab-registration-cell{
        @include breakpoint (medium up) {
            width: rem-calc(393);
        }
        .input-password{
            margin-bottom: rem-calc(26);
        }
    }
}
.page--login,
.page[data-action="Login-Show"]{
    @include breakpoint(large up){
    }
    margin-bottom: rem-calc(60);
    @include breakpoint(ipad down){
        margin-bottom: rem-calc(24);
    }
}
.page[data-action="Login-Show"]{
    @include breakpoint(medium only){
    }
    @include breakpoint(ipad down){
        min-height: unset !important;
    }
}
.page--login[data-action="Account-RegistrationPhoneShow"]{
    @include breakpoint(medium down){
        height: calc(100vh - 100px);
        overflow-x: clip;
        position: relative;
        button{
            width: 100%;
        }
    }
}
.registration-step-final{
    input{
    font-size: rem-calc(14);
    font-weight: 400;
    line-height: rem-calc(21);
    letter-spacing: rem-calc(0.2);
    padding: 0;
    }

    .toggle-password{
        cursor: pointer;
        position: absolute;
        top: rem-calc(28);
        right: rem-calc(0);
        content: url('../../images/password-eye-closed.svg');
        width: rem-calc(20);
        height: rem-calc(20);
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        touch-action: manipulation;
        &.pwd-icon-visible{
            content: url('../../images/password-eye-open.svg');
        }
        @include breakpoint (small down){
            left: rem-calc(320);
        }
    }
    .button-register-final{
        width: 100% !important;
        @include breakpoint(medium down){
            width: 100%;
        }
    }
    @include breakpoint (medium down){
        width: 100%;
    }

    .errors-list.filled{
        margin-top: 0;
    }
}

.tezenis-details-page-spacer{
    padding-bottom: rem-calc(31);
}

.details-page{
    .border-bottom-cell{
        //border-bottom: 1px solid $dark-gray;
        margin-bottom: rem-calc(40);
        align-items: center;
    }
    .go-to-home{
        min-width: rem-calc(200);
        width: 100%;
        @include breakpoint(small down){
            width: 100%;
        }
    }
    .image-step-1-thanks{
        min-width: 75px;
    }
    .buttons-container a{
        min-width: rem-calc(250);
        margin-right: rem-calc(20);
        @include breakpoint(small down){
            width: 100%;
            margin: rem-calc(0 0 20);
        }
    }
}
#login-form-email, #login-form-password {

    width: rem-calc(393);
    @include breakpoint (medium down){
        width: 100%;
    }
    @include breakpoint (ipad down){
        width: rem-calc(393);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @include breakpoint (small down){
        width: 100%;
    }
}

#login-form-password{
    &.is-invalid-input{
        margin: 0;
    }
}
.bold{
    font-weight: bold;
}
.loyalty-text-box{
    @include breakpoint(medium up){
        padding-left: rem-calc(15);
    }
    .dob{
        margin-top: rem-calc(25);
    }
}
.circles-steps-box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(40);
    .circle-item{
        height: rem-calc(30);
        width: rem-calc(30);
        border-radius: 50%;
        background-color: $water-green;
        margin: rem-calc(0 5);

        &.inactive {
            background-color: transparent;
        }

        img {
            &.check{
                margin-top: 25%;
                margin-left: 25%;
                width: 50%;
                height: 50%;
            }
        }

        img {
            width: rem-calc(30);
            height: rem-calc(30);
            margin-right: rem-calc(4);
        }
    }
    .line-item{
        width: rem-calc(18);
        height: 1px;
        background-color: #666666;
        &.inactive{
            background-color: $medium-light-gray;
        }
    }
}

.button-login{
    margin-bottom: rem-calc(24);
}

.button-login-tzn{
    width: rem-calc(393);
    margin-bottom: rem-calc(10);
    @include breakpoint (ipad down){
        margin-bottom: rem-calc(10);
    }
    @include breakpoint (small down){
        width: 100%;
        margin-bottom: 0;
        height: rem-calc(53);
    }
}

.tzn-login-form{
    @include breakpoint (ipad down){
        padding-left: rem-calc(180);
    }
    @include breakpoint (small down){
        padding-left: 0;
    }
}

.new-customer-message{
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.2);
    color: rgba(28, 28, 28, 1);
    margin-bottom: 0;
    @include breakpoint (ipad down){
        padding-left: rem-calc(180);
    }@include breakpoint (small down){
        text-align: left;
        padding-left: 0;
    }
}

.login-form-error{
    background-color: $white;
    border: rem-calc(1.6) solid $water-green;
    border-radius: rem-calc(8);
    color: $black;
    font-size: rem-calc(12);
    padding: rem-calc(24);
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(30);
    width: rem-calc(423);

    @include breakpoint (small down) {
        width: fit-content;
    }
    &::first-letter{
        text-transform: capitalize;
    }
    &#reg-phone-error{
        @include breakpoint(large up){
            margin-bottom: rem-calc(24);
        }
    }
}
.registration-page-title{
    font-weight: 600;
    margin: rem-calc(72 0 9 0);
    font-size: rem-calc(26);
    line-height: rem-calc(34);
    color: $black;
    @include breakpoint(ipad down){
        font-size: rem-calc(18);
        margin-bottom: rem-calc(20);
    }
}
.spacer-40{
    height: rem-calc(40);
}
.registration-page-subtitle{
    font-size: rem-calc(18);
}
.insert-phone-number, .insert-mail-number{
    font-size: rem-calc(18);
    margin-bottom: rem-calc(16);
    @include breakpoint(large up){
        margin-top: rem-calc(55);
    }
    @include breakpoint(small down){
        text-align: center;
        font-size: rem-calc(14);
        margin-bottom: rem-calc(18);
    }
}

.insert-phone-number{
    font-size: rem-calc(16);
    font-weight: 400;
    line-height: rem-calc(24);
    letter-spacing: rem-calc(0.2);
    text-align: center;
    margin: rem-calc(9 0 40 0);
}

.cc-registerPageFlow{
    &-registerPhone{
        .form-control-label-select{
            top: rem-calc(-1);
        }
    }
}

@-moz-document url-prefix() {
    .cc-registerPageFlow{
        &-registerPhone{
            padding-top: rem-calc(26);
            .form-control-label-select{
                top: rem-calc(-1);
            }
        }
    }
    .registration-page-form-block{
        .button-register-phone{
            padding-top: rem-calc(30);
        }
    }
}

.small{
    font-size: rem-calc(12);
    @include breakpoint(small down){
        font-size: rem-calc(11);
    }
}
.privacy-cookie-policy{
    padding-top: 0;
}
.mandatory-fields{
    margin: rem-calc(-5 0 24);
    text-align: right;
    @include breakpoint(ipad down){
        margin-bottom: rem-calc(20);
        text-align: left;
    }
    span{
        font-size: rem-calc(10);
        color: #666666;
    }
}
.js-linkModalPrivacyPolicyMarketing,
.js-linkModalPrivacyPolicyProfiling,
.privacy-cookie-policy a{
    @include breakpoint(medium up){
        text-transform: lowercase;
    }
    @include breakpoint(small down){
        text-transform: uppercase;
    }
}
.privacy-cookie-policy{
    @include breakpoint(ipad down){
        text-align: center;
        margin-top: rem-calc(12);
    }
}
.js-linkModalPrivacyPolicyFull,
.link-privacy{
    border: unset;
}
.hideable.loyalty{
    padding: rem-calc(30 0);
    border-top: 1px solid $border-gray;
    border-bottom: 1px solid $border-gray;
    margin: rem-calc(24 0 40);
    @include breakpoint(ipad down){
        margin-top: rem-calc(20);
        padding: rem-calc(16 0);
    }
    .align-end{
        @include breakpoint(small down){
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }
    .mobile-flex-fields{
        position: relative;
        .popover-loyalty-member-tez{
            min-height: rem-calc(100);
            width: rem-calc(250);
            color: #1c1c1c;
            // background: linear-gradient(180deg, #F0866D 30%, #FF6699 80%);
            background: white;
            position: absolute;
            bottom: rem-calc(70);
            padding: rem-calc(24);
            border-radius: rem-calc(5);
            right: rem-calc(-95);
            //filter: drop-shadow(1px 1px 10px #a2a2a2);
            overflow-wrap: break-word;
            border: 1px solid #82C1BD;
            @include breakpoint(medium down){
                right: 0;
                left: unset;
            }
            .loyalty-popover-title{
                font-size: rem-calc(12);
                line-height: rem-calc(20);
                font-weight: 600;
                margin-bottom: rem-calc(8);
            }
            .loyalty-popover-subtitle{
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: 400;
            }
            img{
                display: unset !important;
                position: absolute;
                top: rem-calc(15);
                right: rem-calc(15);
            }
            &:after, &:before {
                bottom: rem-calc(-22);
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                @include breakpoint(medium down){
                    right: rem-calc(20) !important;
                }
            }

            &:after {
                right: rem-calc(114);
                border-color: transparent;
                border-width: rem-calc(10);
                margin-left: rem-calc(-10);
            }

            // &:before {
            //     right: rem-calc(114);
            //     border-color: transparent;
            //     border-top-color: #FF6699;
            //     border-width: rem-calc(11);
            //     margin-left: rem-calc(-11);
            // }
        }
    }
}
.phone-number-registration-field{
    &#registration-form-prefix{
        padding-left: 0;
    }
    input{
        margin-bottom: rem-calc(30);;
    }
    &:first-of-type{
        //margin-right: rem-calc(20);
        @include breakpoint(large up){
            width: 36%;
        }
    }

}
.registration button, .registrationSocialMail button{
    @include breakpoint(large up){
        width: 36%;
    }
}
.login-box {
    border: 1px solid $border-gray;
    padding: rem-calc(35 0);

    @include breakpoint (small down) {
        border: none;
        padding: 0;
    }

    .bordered {
        border: 1px solid $border-gray;
        padding: rem-calc(35 16 16);
        position: relative;

        @include breakpoint (small down) {
            &.last {
                border-top: none;
            }
        }
    }

    h5 {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        left: rem-calc(32);
        padding: rem-calc(10);
        background: $white;
    }
}

.login-oauth {
    &-icon {
        display: inline-block;

        &:not(:last-child) {
            padding-right: rem-calc(10);
        }
    }
}

.right-align {
    margin-right: 0;
    margin-left: auto;
}

.login-title-account{
    font-weight: 600;
    margin-bottom: rem-calc(15);
    background-color: $light-gray;
    padding: rem-calc(10);
    @include breakpoint (medium up) {
        margin-left: rem-calc(18);
        margin-right: rem-calc(18);
    }
}

.refund-container-center {
    margin: 0 auto !important;
}

html[lang="ru-RU"] #registration-form-prefix {
    text-align: center;
}
.title-thank-you{
    @include breakpoint(medium down){
        text-align: center;
        margin-bottom: rem-calc(40);
    }
}

.label-focused-eye{
    color: #FF6699;
}
.page--login[data-action="Account-NewRegistration"]{
    .grid-container{
        padding: 0;
    }
    .details-page{
        @include breakpoint(large up){
            width: 41.6% !important;
        }
        margin-bottom: rem-calc(60);
        @include breakpoint(medium down){
            padding: rem-calc(0 40);
        }
        @include breakpoint(small down){
            padding: rem-calc(0 20);
        }
    }
}
.download-tezenis-app{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    min-height: rem-calc(250);
    padding: rem-calc(20);
    background-image: linear-gradient(to right, #E58969, #EF8391, #74699C);
    img.mobile-icon{
        margin-bottom: rem-calc(20);
    }
    p.padding-download{
        padding: rem-calc(0 50);
        font-size: rem-calc(16);
        @include breakpoint(small down){
            font-size: rem-calc(14);
        }
    }
    p.strong{
        font-size: rem-calc(20);
        @include breakpoint(small down){
            font-size: rem-calc(18);
        }
    }
    .images-container{
        display: flex;
        img:first-child{
            margin-right: rem-calc(10);
        }
    }
}
.category-grid--tezenis-thanks__wrapper{
    padding: rem-calc(30 0);
}
.categories-asset-thankyou{
    margin-top: rem-calc(80);
    @include breakpoint(small down){
        margin-top: rem-calc(70);
    }
    .slick-list.draggable{
        padding: rem-calc(0 100) !important;
        @include breakpoint(small down){
            padding: rem-calc(0 100) !important;
        }
    }
    .slick-slide:not(.slick-active){
        @include breakpoint(medium up){
            img{
                opacity: 0.5;
            }
            a{
                display: none;
            }
        }
    }
}
.assets-categorySlide-thank-you{
    margin-right: rem-calc(10);
}
.slick-prev, .slick-next{
    z-index: 1;
}
button.slick-next.slick-arrow{
    width: unset;
    right: rem-calc(100);
    &:before{
        left: rem-calc(-12);
        background-image: url(../images/arrow-right-thin.svg);
    }
    &:after{
        content: '';
        position: absolute;
        width: rem-calc(30);
        height: 40px;
        right: rem-calc(9);
        background: white;
        top: rem-calc(-20);
        z-index: -1;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        opacity: 0.6;
    }
}
button.slick-prev.slick-arrow{
    left: rem-calc(100);
    width: unset;
    &:before{
        left: rem-calc(20);
        background-image: url(../images/arrow-left-thin.svg);
    }
    &:after{
        content: '';
        position: absolute;
        width: rem-calc(30);
        height: 40px;
        left: 0;
        background: white;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        top: rem-calc(-20);
        z-index: -1;
        opacity: 0.6;
    }
}

.ico-back-registration {
    &:hover {
        border: 0;
    }
}

.page[data-action="Account-SetNewPassword"] {
    .errors-list.filled .parsley-length {
        display: none !important;
    }
}
.abandoned-cart{
    .socialLoginContainer,
    table[width="100%"]{
        width: 100% !important;
    }
    tr{
        background-color: transparent !important;
    }
}

.footer-sticky-mobile{
    @include breakpoint (medium down){
        position: sticky;
        bottom: 0;
        margin-top: rem-calc(160);
    }
}

.abandoned-cart-title-tab{
    font-size: rem-calc(18);
    line-height: rem-calc(27);
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(0);
    text-shadow: none;
    @include breakpoint(ipad down){
        line-height: rem-calc(24);
        text-align: center;
        margin-top: rem-calc(32);
    }
}

.abandoned-cart-description {
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(24);
    font-size: rem-calc(14);
    font-weight: 500;
    @include breakpoint(ipad down){
        text-align: center;
    }
}

.abandonedCartWrapper {
    .abandoned-text-underline {
        text-decoration-thickness: rem-calc(2);
        font-weight: 700;
        font-size: rem-calc(12);
        @include breakpoint(ipad down){
            font-size: rem-calc(14);
            text-decoration-thickness: rem-calc(3);
        }
    }
    .abandoned-email-input {
        margin-bottom: rem-calc(24);
        @include breakpoint(ipad down){
            transform: scale(.979);
        }
    }
    .abandoned-pwd-input {
        @include breakpoint(ipad down){
            transform: scale(.979);
        }
    }
    .button-login-abandoned{
        margin-top: rem-calc(32);
    }

    .remember-me-abandoned {
        margin-top: rem-calc(6);
    }

    .abandoned-cart-boolean-field{
        margin-bottom: rem-calc(0);
    }

    .social-login-account-accept-abandoned{
        margin-top: rem-calc(24);
    }

    .abandoned-cart-image-wrapper{
        position: fixed;
        width: 50%;
        top: 0;
        left: 0;
        .content-asset {
            img{
                width: 100%;
            }
        }
    }

    .container-tabs{
        .gigyaLoginCell{
            width: rem-calc(393);
        }
    }
    .container-tabs-abandoned {
        @include breakpoint (medium down){
            height: rem-calc(25);
        }
        height: rem-calc(690);
    }
}
